<template>
  <fw-panel :title="tmpEdition.key ? 'Editar edição' : 'Criar edição'">
    <div>
      <fw-label>Nome da edição</fw-label>
      <b-input v-if="editable" v-model="tmpEdition.name"></b-input>
      <div v-else>
        {{ tmpEdition.name }}
      </div>
      <fw-tip v-if="$v.tmpEdition.name.$error" error> Insira um nome para o torneio</fw-tip>
    </div>
    <div>
      <fw-label>Datas de início e fim da edição</fw-label>
      <div v-if="editable" class="flex gap-5">
        <b-datepicker
          v-model="tmpEdition.start_at"
          :min-date="minDate"
          :years-range="[0, 50]"
          locale="pt-PT"
          :placeholder="'Data de início'"
        >
        </b-datepicker>
        <b-datepicker
          v-model="tmpEdition.end_at"
          :min-date="tmpEdition.start_at || minDate"
          :years-range="[0, 50]"
          locale="pt-PT"
          :placeholder="'Data de fim'"
        >
        </b-datepicker>
      </div>
      <div v-else class="flex gap-5">{{ tmpEdition.start_at | formatDate }} - {{ tmpEdition.end_at | formatDate }}</div>
      <fw-tip v-if="$v.tmpEdition.start_at.$error" error> Insira data de início </fw-tip>
      <fw-tip v-if="$v.tmpEdition.end_at.$error" error> Insira data de fim </fw-tip>
    </div>

    <div class="flex flex-shrink-0 pt-5 gap-3">
      <div class="flex-1"></div>
      <fw-button type="link-muted" @click.native="closeModal()">
        {{ 'Cancelar' }}
      </fw-button>
      <fw-button
        v-if="tmpEdition.key && editable"
        :type="'xlight'"
        :disable="saving"
        :saving="saving"
        class="w-28"
        @click.native="publish()"
      >
        {{ 'Publicar' }}
      </fw-button>
      <fw-button
        v-if="tmpEdition.key && editable"
        :type="'primary'"
        :disable="saving"
        :saving="saving"
        class="w-28"
        @click.native="sendData()"
      >
        {{ 'Guardar' }}
      </fw-button>
      <fw-button
        v-else-if="editable"
        :type="'primary'"
        :disable="saving"
        :saving="saving"
        class="w-28"
        @click.native="sendData()"
      >
        {{ 'Criar' }}
      </fw-button>
    </div>
  </fw-panel>
</template>
<script>
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import { required } from 'vuelidate/lib/validators'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
export default {
  name: 'ModalCreateEdition',
  components: {},
  props: {
    edition: {
      type: Object,
    },
  },
  data() {
    return {
      saving: false,
      minDate: Dates.now()
        .subtract(1, 'day')
        .toDate(),
      tmpEdition: {
        name: '',
        start_at: null,
        end_at: null,
        status: 'draft',
      },
    }
  },
  computed: {
    editable() {
      return this.tmpEdition.status != 'published'
    },
    api() {
      return this.$store.state.api.base
    },
  },
  mounted() {
    if (this.edition != null) {
      console.log(this.edition)
      let data = JSON.parse(JSON.stringify(this.edition))
      data.start_at = new Date(data.start_at)
      data.end_at = new Date(data.end_at)
      this.tmpEdition = data
    }
  },
  validations: {
    tmpEdition: {
      name: { required },
      start_at: { required },
      end_at: { required },
    },
  },
  methods: {
    async publish() {
      this.sendData(true)
    },
    async sendData(publish) {
      this.$v.$touch()
      if (this.$v.tmpEdition.$error) return
      this.saving = true
      utils.tryAndCatch(
        this,
        async () => {
          let data = JSON.parse(JSON.stringify(this.tmpEdition))
          if (data.start_at) {
            data.start_at = this.tmpEdition.start_at.toISOString().split('T')[0]
          }
          if (data.end_at) {
            data.end_at = this.tmpEdition.end_at.toISOString().split('T')[0]
          }
          if (publish) {
            data['submit'] = true
          }
          const response = this.tmpEdition.key
            ? await this.api.updateEdition(this.tmpEdition.key, data)
            : await this.api.createEdition(data)
          this.$emit('saved', response)
        },
        () => {
          this.saving = false
        }
      )
    },
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>
